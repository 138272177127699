import React from 'react';

import { Meta } from '../components/Meta';
import { MainLayout } from '../components/MainLayout';
import { HeroSection } from '../components/HeroSection';
import { OurProcess } from '../components/OurProcess';
import { ServiceDetailsSection } from '../components/ServiceDetailsSection';

import HeroImage from '../images/lawn-care.webp';
import Seeding from '../images/seeding.webp';
import Aerating from '../images/aerating.webp';
import Fertilizing from '../images/fertilizing.webp';
import Mowing from '../images/mowing.webp';
import Dethatch from '../images/dethatch.webp';
import Edging from '../images/edging.webp';
import Grub from '../images/grub.webp';
import Fungus from '../images/fungus.webp';
import Overseeding from '../images/overseeding.webp';

const heroTitle = "Nantucket's Lawn Care Experts";
const heroSubtitle = 'Servicing Nantucket for over 20  years.';

const serviceTitle = 'Merlos Lawn & Maintenance';
const serviceSubtitle =
  'We at Merlos Landscaping are proud to offer full lawn and maintenance services with over 20 years serving Nantucket Island.  We provide everything from seeding to treating and mowing. Our experts will give you the finest lawn care.';
const services = [
  {
    title: 'Mowing',
    description:
      'Merlos offers regular mowing.  We have a professional team of mowers that only uses high-end equipment to give your lawn a professional cut every time.',
    image: Mowing,
  },

  {
    title: 'Aerating',
    description:
      'Our team will aerate your lawn every year to make sure it is getting the proper nutrients and amount of water for ideal growth and presence.',
    image: Aerating,
  },
  {
    title: 'Fertilizing',
    description: 'We at Merlos use the highest quality fertilizer to ensure your lawn is healthy and bright.',
    image: Fertilizing,
  },
  {
    title: 'Lawn Seeding',
    description:
      'Merlos will provide your lawn with the proper seeding to ensure a healthy and rich lawn. We can seed brand new lawns or revive your existing lawn with seasonal seeding.',
    image: Seeding,
  },
  {
    title: 'Dethatch',
    description:
      'Every year we will dethatch your lawn to remove unwanted roots that could prevent your lawn from staying healthy and thick.',
    image: Dethatch,
  },
  {
    title: 'Edging',
    description:
      'Our experts will perfectly trim and manicure your lawn and bushes to ensure that all edges have a professional cut, giving your lawn a pristine look.',
    image: Edging,
  },
  {
    title: 'Grub Prevention',
    description:
      'Grubs are a common issue on the island and will kill a lawn if they are not identified and managed quickly. Our team looks for signs and takes action immediately.',
    image: Grub,
  },
  {
    title: 'Fungus Prevention',
    description:
      'There are a number of varities of lawn fungus that can be found on the island. Some varities can spread across an entire lawn in just a couple of days . Our experts look for signs and take preventative measures when neccessary.',
    image: Fungus,
  },
  {
    title: 'Overseeding',
    description:
      'Overseeding ensures a thick, green, and healthy lawn. Most often, overseeding is done in the spring and falls months, but will also be applied as necessary.',
    image: Overseeding,
  },
];

export default function LawnCarePage() {
  return (
    <MainLayout>
      <Meta route="lawn" />
      <HeroSection title={heroTitle} subtitle={heroSubtitle} image={HeroImage} />
      <ServiceDetailsSection title={serviceTitle} subtitle={serviceSubtitle} services={services} showServicesHeader />
      <OurProcess />
    </MainLayout>
  );
}
