import * as React from 'react';
import { Layout } from './Layout';
import { OurWork } from './OurWork';
import { OurClients } from './OurClients';

export function MainLayout({ children }) {
  return (
    <Layout>
      {children}
      <OurWork />
      <OurClients />
    </Layout>
  );
}
