import React from 'react';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import Leaves from '../images/leaves.webp';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '7% 15%',
    marginBottom: '30px',
  },
  title: {
    fontWeight: 300,
    textAlign: 'center',
  },
  subtitle: {
    fontWeight: 400,
    textAlign: 'center',
  },
  servicesHeader: {
    marginTop: '2em',
  },
  servicesContainer: {
    paddingTop: '10%',
    textAlign: 'center',
    backgroundImage: `url(${Leaves})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  serviceItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  serviceItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 290,
  },
  serviceImage: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: 30,
    height: 190,
    marginBottom: '10%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '5%',
    },
  },
  serviceTitle: {
    marginBottom: '10%',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '5%',
    },
  },
  serviceDescription: {
    fontWeight: 400,
    lineHeight: '1.4em',
    color: theme.palette.secondary.main,
  },
}));

export function ServiceDetailsSection({ title, subtitle, services, showServicesHeader }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      <Box mt={3} />
      <Typography variant="h5" color="textSecondary" className={classes.subtitle}>
        {subtitle}
      </Typography>
      {showServicesHeader && (
        <Typography variant="h3" className={classes.servicesHeader} align="center">
          Our Services
        </Typography>
      )}
      <Grid container className={classes.servicesContainer} justify="center" spacing={8}>
        {services.map((service, index) => (
          <Grid item key={index} className={classes.serviceItem} md={4} sm={6} xs={12}>
            <Box className={classes.serviceItemWrapper}>
              <div className={classes.serviceImage} style={{ backgroundImage: `url(${service.image})` }} />
              <Typography variant="h5" color="primary" className={classes.serviceTitle}>
                {service.title}
              </Typography>
              <Typography variant="h5" color="textSecondary" className={classes.serviceDescription}>
                {service.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
