import * as React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '15%',
    paddingRight: '15%',
    marginBottom: '100px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '50px',
    },
  },
  header: {
    marginBottom: '0.5em',
  },
  description: {
    marginBottom: '3em',
  },
  subheader: {
    color: '#4d4d4d',
    fontWeight: 600,
    fontSize: '30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
    marginBottom: '1em',
  },
  list: {
    fontWeight: 300,
    listStyle: 'none',
    lineHeight: '2em',
    margin: 0,
    padding: 0,
    fontSize: '30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  listNumber: {
    flexShrink: 0,
    borderRadius: '50%',
    background: 'black',
    color: 'white',
    width: '2em',
    height: '2em',
    fontSize: '15px',
    marginRight: '10px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      width: '1.5em',
      height: '1.5em',
    },
  },
}));

const items = ['Grabgrass Preventer & Lime', 'Weed & Feed', 'Green up', 'Regular', 'Regular', 'Winter Preventer'];

export function OurProcess() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h3" className={classes.header} align="center">
        Our Process
      </Typography>
      <Typography variant="h5" color="textSecondary" align="center" className={classes.description}>
        With over 20 years of lawn care on Nantucket, we have perfected an organic process that keeps a lawn thick and
        healthy all year round.
      </Typography>
      <Typography variant="h3" className={classes.subheader} align="center">
        Our 6 Step Fertilizing Process
      </Typography>
      <ol className={classes.list}>
        {items.map((item, idx) => (
          <li key={idx} className={classes.listItem}>
            <span className={classes.listNumber}>{idx + 1}</span>
            {item}
          </li>
        ))}
      </ol>
    </div>
  );
}
