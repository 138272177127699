import * as React from 'react';
import { Box, Typography, Grid, makeStyles } from '@material-ui/core';
import HeroSectionImage from '../images/hero-section.webp';

const useStyles = makeStyles((theme) => ({
  heroSection: {
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'center',
      height: 'auto',
    },
  },
  heroSectionBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '-100',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    animationDuration: '60s',
    animationTimingFunction: 'linear',
    animationName: '$backgroundZoomNShift',
    animationIterationCount: 'infinite',
  },
  '@keyframes backgroundZoomNShift': {
    '0%': {
      backgroundPositionX: '0%',
      transform: 'scale(1)',
    },
    '50%': {
      backgroundPositionX: '10%',
      transform: 'scale(1.1)',
    },
    '100%': {
      backgroundPositionX: '00%',
      transform: 'scale(1)',
    },
  },
  heroSectionText: {
    color: 'white',
    textAlign: 'center',
    padding: '7% 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '8% 2% 4%',
    },
  },
}));

export function HeroSection({ title, subtitle, image }) {
  const classes = useStyles();

  return (
    <div className={classes.heroSection}>
      <div
        className={classes.heroSectionBackground}
        style={{ backgroundImage: `url(${image})` }}
        src={HeroSectionImage}
      ></div>
      <Grid container alignItems="center" className={classes.heroSectionText}>
        <Grid item xs={12}>
          <Typography variant="h2">{title}</Typography>
        </Grid>
        <Box mt={3} width="100%" />
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.responsiveText15}>
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
